<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('distributionAreaFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right"></div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="distributionAreas"
            :fields="fields"
            :items-per-page="10"
            :loading="loading"
            hover
            pagination
            clickable-rows
            v-on:refresh="filterDistricts"
          >
            <template #distributionAreaHepsijet="{item}">
              <td>
                <CIcon
                  v-if="item.distributionAreaHepsijet"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #distributionAreaPackupp="{item}">
              <td>
                <CIcon
                  v-if="item.distributionAreaPackupp"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CButton
                  color="info"
                  square
                  size="sm"
                  @click="openModalEvent('update','distributionAreaForm', item, 'Düzenle')"
                >
                  Düzenle
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <FormModal 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="update"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"
    />

    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterDistricts"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />

  </CRow>
</template>

<script>
import FormModal from '../components/formModal'
import FilterSidebar from '../components/filterSidebar'

export default {
  name: 'DistributionAreas',
  data() {
    return {
      distributionArea: {},
      openModal: false,
      openSidebar: false,
      modalTitle: '',
      actionType: '',
      module: 'operation',
      form: 'countriesForm',
      filterForm: 'distributionAreaFilter',
      filterParams: {countryId: 192},
      modalProps: {},
      modalDesc: '',
      activePage: 1,
      
      fields: [
      {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'min-width:1%'
        },
        { key: 'id', label: 'İlçe ID', _style: 'font-size:12px' },
        { key: 'name', label: 'İlçe', _style: 'font-size:12px' },
        { key: 'cityId', label: 'İl ID', _style: 'font-size:12px' },
        {
          key: 'distributionAreaHepsijet',
          label: 'HEPSİJET',
          _style: 'font-size:12px'
        },
        {
          key: 'distributionAreaPackupp',
          label: 'PACKUPP',
          _style: 'font-size:12px'
        },
       
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  components:{
    FormModal, FilterSidebar
  },
  computed: {
    distributionAreas() {
      let data = this.$store.getters.distributionAreas

      data.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          o[key] === null ? (o[key] = '') : (o[key] = o[key])
        })
      })

      return data
    },
    
    loading() {
      return this.$store.getters.operationLoading
    }
  },
  methods: {

    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async update(item) {
      let formData = new FormData()
      formData.append('districtId', item.id)
      formData.append('isActiveHepsijet', item.distributionAreaHepsijet)
      formData.append('isActivePackupp', item.distributionAreaPackupp)

      await this.$store.dispatch('distributionArea_action', formData)

      if(this.$store.getters.operationStatus.success){
        await this.filterDistricts()
        await this.openModalEvent()
      }
    },

    async filterDistricts(params) {
        //Backend tarafında filtreleme fonksiyonu değişince tekrar kontrol edilecek
        params.shippingCompanyId = params.shippingCompanyId ? params.shippingCompanyId : null
        params.activeState = params.activeState ? params.activeState : null

        this.filterParams = params
        let formData = new FormData()
        Object.keys(params).map(p => {
          formData.append(p, params[p])
        })

        await this.$store.dispatch('distributionArea_list', formData)

        if(this.openSidebar){
          await this.openSidebarEvent()
        }
    },
  },

  created() {
    this.$store.dispatch('allCountries_list')
    this.$store.dispatch('allCities_list', {countryId: 192})
  }
}
</script>
