<template>
    <CModal
        :show="show"
        :centered="true"
        :title="title"
        :size="size ? size : 'lg'"
        color="dark"
        @update:show="updateModalShow"
    >
        <div class="flexCenter" v-show="loading">
            <CSpinner  color="primary"/>
        </div>
        <component 
            v-show="!loading"
            :is="customComponent" 
            ref="customComponent" 
            :params="data" 
            :actionType="actionType" 
            :additionalEvent="additionalEvent" 
            :list="list"
            :validated="validated"
            :modalHandle="modalHandle"
        ></component>

        <template #footer>
            <span v-if="noFooter">
            </span>
            <span v-else>
                <CButton style="margin-right: 1rem;"  v-for="b in additionalButtons"  @click="modalHandle('additional', b)" :key="b.name" :color="b.color"> {{ b.name }} </CButton>
                <CButton style="margin-right: 1rem;"  @click="modalHandle('save')" color="success"> {{ buttonTitle ? buttonTitle : 'Kaydet'}} </CButton>
                <CButton  @click="modalHandle('cancel')" color="dark"> Kapat </CButton>
            </span>
           

            
        </template>

        <ConfirmModal  
            :show="openModal" 
            :title="confirmTitle" 
            :openModalEvent="confirmModalEvent"
            :desc="desc"
            :confirm="modalHandle"
        />
    </CModal>
</template>

<script>
    import ConfirmModal from './confirmModal.vue'
    export default{
        name: 'Modal',
        props:{
            show: Boolean,
            title: String,
            openModalEvent: Function,
            operationEvent: Function,
            module: String,
            form: String,
            actionType: String,
            data: Object,
            loading: Boolean,
            additionalButtons: Array,
            additionalEvent: Function,
            list: Function,
            size: String,
            noFooter: Boolean,
            noConfirm: Boolean,
            buttonTitle: String
        },
        data: function(){
            return{
                openModal: false,
                validated: null,
                confirmTitle: '',
                desc: '',
                lastOperation: '',
            }
        },
        watch: {
            show: function(){
                if(this.$refs.customComponent)
                    this.$refs.customComponent.$refs.form.classList.remove('was-validated')
            }
        },
        components:{
            ConfirmModal
        },
        computed:{
            // eslint-disable-next-line vue/return-in-computed-property
            customComponent: function(){
                if(this.module && this.form)
                    return () => import(`../${this.module}/modal/${this.form}` )
            }
        },
        methods:{
            updateModalShow: function(){
                this.lastOperation = 'cancel'
                if(!this.noFooter){
                    this.confirmModalEvent('Dikkat', 'Yaptığınız değişiklikleri iptal etmek üzeresiniz. Bu işlemi onaylıyor musunuz?')
                }else{
                    this.openModalEvent();
                }
            },
            confirmModalEvent(title, desc){
                this.openModal = !this.openModal
                this.confirmTitle = title
                this.desc = desc
            },
            modalHandle(data, additional){
                if(data == 'cancel'){
                    this.lastOperation = 'cancel'
                    if(!this.noFooter){
                        this.confirmModalEvent('Dikkat', 'Yaptığınız değişiklikleri iptal etmek üzeresiniz. Bu işlemi onaylıyor musunuz?')
                    }else{
                        this.openModalEvent();
                    }
                }else if(data == 'save'){

                    let domForm;

                    if(this.$refs.customComponent.$refs.main){
                        domForm = this.$refs.customComponent.$refs.main.$refs.form.checkValidity ? this.$refs.customComponent.$refs.main.$refs.form : this.$refs.customComponent.$refs.main.$refs.form[0]
                    } else {
                        domForm = this.$refs.customComponent.$refs.form.checkValidity ? this.$refs.customComponent.$refs.form : this.$refs.customComponent.$refs.form[0]
                    }
                   
                    this.lastOperation = 'save'
                    if(domForm.checkValidity() == false){
                        this.validated = this.validated !== false ? false : null
                        domForm.classList.add('was-validated')
                    }else{
                        if(this.noConfirm){
                            if(this.$refs.customComponent.$refs.main)
                                this.operationEvent(this.$refs.customComponent.$refs.main.data, this.actionType)
                            else
                                this.operationEvent(this.$refs.customComponent.data, this.actionType)
                        }else
                            this.confirmModalEvent('Dikkat', 'Yaptığınız değişiklikleri kaydetmek üzeresiniz. Bu işlemi onaylıyor musunuz?')
                    }
                }else if(data == 'additional'){
                    this.additionalEvent && this.additionalEvent(this.$refs.customComponent.data, additional.action);
                }else{
                    this.confirmModalEvent();
                    if(this.lastOperation == 'save'){
                        if(this.$refs.customComponent.$refs.main)
                            this.operationEvent(this.$refs.customComponent.$refs.main.data, this.actionType)
                        else
                            this.operationEvent(this.$refs.customComponent.data, this.actionType)

                    }else{
                        this.openModalEvent();
                    }
                }
                
            }
        }
    }

</script>
<style scoped>
 .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
 }
</style>